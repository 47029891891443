import kotlin.js.Date
// Even though Appear2mee is effectively a singleton, different pages of a website might be active. So try not to do interfer with each other.
// Simple lock can still go wrong as time to set/clear is small but not zero.
class Lock {
    var lockKey : String
    var type : String
    constructor(type: String, uuid:String) {
        this.type =  "_2mee_" + type
        lockKey = uuid
    }

    fun aquire() : Boolean {
        val preparedLock = lockKey + ":" + Date().getTime().toString() // should set lock as quickly as possible
        //console.log("prepare " + type + ":" + preparedLock)
        val lock = LocalStorage.getItem(type + "lock")
        if(lock == null) {
            LocalStorage.setItem(type + "lock",preparedLock)
            return true
        } else {
            val parts = lock.split(":")
            if(parts.count() == 2) {
                if(parts[0] == lockKey) { // is my lock so update time
                    if(lock == LocalStorage.getItem(type + "lock")) { // still the same!
                        LocalStorage.setItem(type + "lock",preparedLock)
                        return true
                    } else {
                        return false // has been change!
                    }
                } else { // some other locker ... check time
                    val time = parts[1].toDoubleOrNull()
                    if(time != null) {
                        if((Date().getTime() - 1000*30) > time) {
                            // lock expired
                            if(lock == LocalStorage.getItem(type + "lock")) { // still the same!
                                LocalStorage.setItem(type + "lock", preparedLock)
                                return true
                            } else {
                                return false
                            }
                        }
                    }
                }
            }
            // some kind of error at this point as not true lock was found
            if(lock == LocalStorage.getItem(type + "lock")) { // still the same!
                LocalStorage.setItem(type + "lock", preparedLock)
                return true
            } else {
                return false
            }
        }
    }

    fun release() {
        val lock = LocalStorage.getItem(type + "lock")
        if (lock != null) {
            val parts = lock.split(":")
            if (parts.count() == 2) {
                if (parts[0] == lockKey) { //is my lock so I can clear
                    if (lock == LocalStorage.getItem(type + "lock")) { // still the same!
                        LocalStorage.removeItem(type + "lock")
                    }
                }
            }
        }
    }
}

