import kotlinx.browser.localStorage

class LocalStorage {
    companion object {
        var extraKey = ""

        fun key(index: Int): String? {
            return localStorage.key(index)
        }

        fun removeItem(key: String) {
            localStorage.removeItem(key + extraKey)
        }

        fun clear() {
            localStorage.clear()
        }

        fun getItem(key: String): String? {
            return localStorage.getItem(key + extraKey)
        }

        fun _getItem(key: String): String? {
            return localStorage.getItem(key)
        }

        fun setItem(key: String, value: String) {
            localStorage.setItem(key + extraKey, value)
        }
    }
}