import org.w3c.dom.HTMLElement

class SweetAlertShowClass {
    var popup: String? = null
    var backdrop: String? = null
    var icon: String? = null

    init {
        popup = "appear2mee-popup-show"
    }
}

class  SweetAlertHideClass {
    var popup: String? = null
    var backdrop: String? = null
    var icon: String? = null
    init {}
}

class SweetAlertOptions {
    /*   var title: dynamic = null/* String | HTMLElement | JQuery */
       var titleText: String
       var text: String? */
    var html: dynamic = null /* String | HTMLElement | JQuery */
    /*   var footer: dynamic /* String | HTMLElement | JQuery */
       var icon: String /* 'success' | 'error' | 'warning' | 'info' | 'question' */
       var iconHtml: String? */
       var backdrop: dynamic = backdropValue /* Boolean | String */
    var toast: Boolean = false
    /*

       var target: dynamic /* String | HTMLElement */
       var input: String /* 'text' | 'email' | 'password' | 'number' | 'tel' | 'range' | 'textarea' | 'select' | 'radio' | 'checkbox' | 'file' | 'url' */
       var width: dynamic /* Number | String */
       var padding: dynamic /* Number | String */

     */
    var background: String? = null
    var position: String = "bottom-end" /* 'top' | 'top-start' | 'top-end' | 'top-left' | 'top-right' | 'center' | 'center-start' | 'center-end' | 'center-left' | 'center-right' | 'bottom' | 'bottom-start' | 'bottom-end' | 'bottom-left' | 'bottom-right' */

    // var grow: dynamic /* 'row' | 'column' | 'fullscreen' | Boolean */
     var showClass: SweetAlertShowClass? = SweetAlertShowClass()
     var hideClass: SweetAlertHideClass? = null
    /*  var customClass: SweetAlertCustomClass? */

     // var timer: Number? = null
    //  var timerProgressBar: Boolean?
     // var animation: dynamic /* Boolean | () -> Boolean */
      var heightAuto: Boolean? = true

     var allowOutsideClick: Boolean? = outsideClickEnabled /* Boolean | () -> Boolean */
    /*
     var allowEscapeKey: dynamic /* Boolean | () -> Boolean */ */
     var allowEnterKey: Boolean? = false /* Boolean | () -> Boolean */
/*     var stopKeydownPropagation: Boolean?
     var keydownListenerCapture: Boolean? */
    var showConfirmButton: Boolean? = true
    var showCancelButton: Boolean? = false
    var confirmButtonText: String? = "OK"
    var cancelButtonText: String? = "Cancel"
    /*  var confirmButtonColor: String?
      var cancelButtonColor: String?
      var confirmButtonAriaLabel: String?
      var cancelButtonAriaLabel: String?
      var buttonsStyling: Boolean?
      var reverseButtons: Boolean?

     */
    var focusConfirm: Boolean? = true
    /*
      var focusCancel: Boolean?
    */

    var showCloseButton: Boolean? = true
    var closeButtonHtml: String? = "&times;"
    var closeButtonAriaLabel: String? = "Close this dialog"
    /*
      var showLoaderOnConfirm: Boolean?
      val preConfirm: ((inputValue: Any) -> dynamic)?
      var imageUrl: String?
      var imageWidth: Number?
      var imageHeight: Number?
      var imageAlt: String?
      var inputPlaceholder: String?
      var inputValue: dynamic /* String | Promise<String> */
      var inputOptions: dynamic /* Map<String, String> | `T$0` | Promise<dynamic /* Map<String, String> | `T$0` */> */
      var inputAutoTrim: Boolean?
      val inputValidator: ((inputValue: String) -> dynamic)?
      var validationMessage: String?
      var progressSteps: Array<String>?
      var currentProgressStep: String?
      var progressStepsDistance: String? */
    var willOpen: ((modalElement: HTMLElement) -> Unit)? = null
    var didOpen: ((modalElement: HTMLElement) -> Unit)? = null
    var didRender: ((modalElement: HTMLElement) -> Unit)? = null
    var willClose: ((modalElement: HTMLElement) -> Unit)? = null

    /*   val onAfterClose: (() -> Unit)? */
       var didDestroy: (() -> Unit)? = null
    var scrollbarPadding = false

    init {
    }
}

enum class DismissReason {
    cancel,
    backdrop,
    close,
    esc,
    timer
}

class SweetAlertResult {
    var value: Any? = null
    var dismiss: DismissReason? = null
}


