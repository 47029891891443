
import org.w3c.xhr.XMLHttpRequest
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.*
import kotlin.js.Date
import kotlin.js.Json
import kotlin.js.json

/*
external class Player2meeClass(options:Any) {
    fun html():String
    fun open(): Unit
    fun close(): Unit
    fun pause(): Unit
}
*/

data class MessageSchedule(val playTime: Double?,
                           val expiryTime: Double?,
                           val active: Boolean = true,
                           val eventid: String?,
                           var topics: Array<String>?)

data class BackgroundPlayerParams(val background: String,
                           val width: String,
                           val height : String
                           )

fun MessageSchedule.playAndExpiryMilliSecs(): Pair<Double?, Double?> {
    val playTime = this.playTime
    val expiry = this.expiryTime
    if (expiry == null || playTime == null) return Pair(null, null)
    return Pair(playTime, playTime + expiry * 1000)
}

data class MessagesFromServer(val lastUpdatedAt: String?, val timeIntervalSecForNextCall:String?,
                              val events: Array<MessageSchedule>?)

data class Button2mee(val title: String?, val type: String?)

data class Message2mee(val jobId: String?,
                       val html: String?,
                       val available: Boolean?,
                       var link: String?,
                       var expiryTimeMilliSecs: Double? = null,
                       var canPlay: Boolean = false,
                       var url: String? = null,
                       var keyValues : String? = null,
                       var slot:Slot2mee? = null,
                       var isDesktop: Boolean = false,
                       var blocking: Boolean = true,
                       var offset: Int = 0,
                       var width: Int? = 0,
                       var position: String? = null,
 )

data class Slot2mee(val slot: String?,
                    var message: String?,
                    var link:String?,
                    var about:String?,
                    var jobid:String,
                    var slotName:String?,
                    var isSMS:Boolean?,
                    var offer: OfferId? = null,
                    var limit:Int? = null,
                    var delay:Int? = null,
                    var bannerHeight: Int? = null,
                    var bannerWidth: Int? = null,
                    var bannerNum: Int = 0,
                    var preloader: String = "false",
                    var backgroundSize: Pair<String,String>? = null,
                    var cta : Json? = null,
                    var element: String? = null
                    )

enum class MessageType {
    SMS, LNK, EVENT, EVENT_OFFER, PAGE, PULLED, TIMED_EVENT, INLINE, INLINE_OFFER;
    fun allowBanner() : Boolean {
        if(!largerScreenSize()) return false
        if(this == SMS) return true
        if(this == INLINE || this == INLINE_OFFER) return true
        if(this == EVENT || this == EVENT_OFFER) return true
        if(this == PULLED) return true
        return false
    }
}

external fun encodeURIComponent(component: String): String

fun slotToJsonString(slot:Slot2mee) : String {
    val thejobid = slot.jobid
    var thelink = slot.link
    if(thelink == null) {
        thelink = ctaSimpleLink(slot)
    }
    var ctaComplexFlag = ""
    if(thelink == null) {
        // could be complex cta
        if(ctaComplex(slot)) {
          ctaComplexFlag = ", \"cta\":\"1\""
        }
    }
    var ctaclick = ""
    if(thelink == null) {
        // could be complex cta
        var clickName = ctaClickName(slot)
        if (clickName != null) {
            ctaclick = ", \"clickName\":\"" + clickName + "\""
        }
    }
    ctaComplexFlag = ctaComplexFlag + ctaclick

    val theSlotName = slot.slotName
    if (theSlotName == null) {
        if (thelink == null) {
            return "{\"jobid\":\"" + thejobid + "\""  +  ctaComplexFlag  + "}"
        }
        return "{\"jobid\":\"" + thejobid + "\",\"link\":\"" + thelink + "\"" +  ctaComplexFlag  + "}"
    }
    if (thelink == null) {
        return "{\"name\":\"" + theSlotName + "\"," + "\"jobid\":\"" + thejobid + "\"" +  ctaComplexFlag  + "}"
    }
    return "{\"name\":\"" + theSlotName + "\"," + "\"jobid\":\"" + thejobid + "\",\"link\":\"" + thelink + "\"" +  ctaComplexFlag  + "}"
}

fun messageToJsonString(slot:Message2mee) : String {
    var mySlot = slot.slot
    if(mySlot != null) return slotToJsonString(mySlot)
    val thejobid = slot.jobId
    var thelink = slot.link
    if (thelink == null) {
        return "{\"jobid\":\"" + thejobid + "\"}"
    }
    return "{\"jobid\":\"" + thejobid + "\",\"link\":\"" + thelink + "\"}"
}

fun reportMessage(jobId:String,link:String?,action:String) {
    val registered = getRegistered()
    if (registered == null) return
    val now = Date().getTime()

    val dict = json("from" to registered, "sentAt" to now, "jobid" to jobId, "action" to action)
    val userID = getUserID()
    if (userID != null) {
        dict["userID"] = userID
    }
    val webToken = find2meeWebToken()
    dict["webToken"] = webToken

    val referer = LocalStorage.getItem("_2meeReferer")
    if(referer !=null) {
        dict["externalRef"] = referer
    }
    val data = JSON.stringify(dict)
    reportToExchange(data,null)
}

@Suppress("UNUSED_VARIABLE")
class Message2meeCheck {
    var canLaunchEvent: Boolean = true
    var smsOrUserEventActive: Boolean = false
    var theLaunchedEvent: String? = null
    var player2mee : dynamic = null
    var theLaunchedJobId: String? = null
    var findMessagesIntervalSecs: Int = 120 // 120 secs

    var documentHidden : Boolean = false // assume as we have just started doc is visible
    var wantsScheduledMessages = true
    companion object {
        val pullIP = pullSlotIP
        val slotIP = pullSlotIP

        fun learnTopics() {
            if(useTopics.count() == 0 ) return
            if (!isRegistered()) {
                window.setTimeout({
                    delayedLearnTopics(1)
                }, 100) // do not get message straight away
                return
            }
            //console.log("send " + myLastUpdated + " " + myid)
            val dict = json( "topics" to useTopics.keys)
            val failFunction: () -> Unit = { ->  }
            postData(pullIP + "/web/topics", JSON.stringify(dict), failFunction) { response ->
                //console.log(response)
            }
        }

        private fun delayedLearnTopics(timeout:Int) {
            if(isRegistered()) {
                learnTopics()
            } else {
                window.setTimeout({
                    delayedLearnTopics(timeout*timeout)
                }, 1000 * timeout * timeout) // do not get message straight away
            }
        }

        private fun delayedSlot(timeout:Int,slotName:String,callback: (Slot2mee?) -> Unit) {
            if(isRegistered()) {
                slot(slotName,callback)
            } else {
                window.setTimeout({
                    delayedSlot(timeout*timeout,slotName,callback)
                }, 1000 * timeout * timeout) // do not get message straight away
            }
        }

        fun slotOrSMS(slotOrSMS:String,messageType: MessageType,callback: (Slot2mee?) -> Unit) {
            if(messageType == MessageType.SMS || messageType == MessageType.LNK) {
                smsSlot(slotOrSMS,callback)
            } else {
                slot(slotOrSMS,callback)
            }
        }

        private fun delayedSlotAbout(timeout:Int,slotName:String,callback: (Json?,String?) -> Unit) {
            if(isRegistered()) {
                slotAbout(slotName,callback)
            } else {
                window.setTimeout({
                    delayedSlotAbout(timeout*timeout,slotName,callback)
                }, 1000 * timeout * timeout) // do not get message straight away
            }
        }

        private fun delayedSmsInfo(timeout:Int,smsName:String,callback: (Json?,String?) -> Unit) {
            if(isRegistered()) {
                smsInfo(smsName,callback)
            } else {
                window.setTimeout({
                    delayedSmsInfo(timeout*timeout,smsName,callback)
                }, 1000 * timeout * timeout) // do not get message straight away
            }
        }

        private fun delayedSlotInfo(timeout:Int,smsName:String,callback: (Json?,String?) -> Unit) {
            if(isRegistered()) {
                slotInfo(smsName,callback)
            } else {
                window.setTimeout({
                    delayedSlotInfo(timeout*timeout,smsName,callback)
                }, 1000 * timeout * timeout) // do not get message straight away
            }
        }


        fun smsInfo(smsid:String, callback: (Json?, String?) -> Unit) {
            if (!isRegistered()) {
                window.setTimeout({
                    delayedSmsInfo(1,smsid,callback)
                }, 100) // do not get message straight away
                return
            }
            //console.log("send " + myLastUpdated + " " + myid)
            val dict = json( "jobid" to smsid)
            val failFunction: () -> Unit = { ->  }
            postData(slotIP + "/web/sms/slot", JSON.stringify(dict), failFunction) { response ->
                val slot = JSON.parse<Slot2mee>(response)
                if(slot != null) {
                    val json = JSON.parse<Json>(response)
                    var css = paramFromBrowser("2meeCSS")
                    if(css == null) {
                        css = "appearTopRight"
                    }
                    json.set("css",css)
                    json.set("sms", slot.slotName)
                    callback(json,slot.slotName)
                } else {
                    callback(json(),null)
                }
            }
        }

        fun slotInfo(slotName:String,callback: (Json?, String?) -> Unit) {
            if (!isRegistered()) {
                window.setTimeout({
                    delayedSlotInfo(1,slotName,callback)
                }, 100) // do not get message straight away
                return
            }
            //console.log("send " + myLastUpdated + " " + myid)
            val dict = json( "name" to slotName)
            val failFunction: () -> Unit = { -> callback(json(),null) }
            postData(slotIP + "/web/slot", JSON.stringify(dict), failFunction) { response ->
                val slot = JSON.parse<Slot2mee>(response)
                //console.log(slot)
                if(slot.slot != null) { // slot is usr of message v
                    val json = JSON.parse<Json>(response)
                    callback(json,slotName)
                } else {
                    callback(json(),null)
                }
            }
        }


        fun slotAbout(slotName:String,callback: (Json?, String?) -> Unit) {
            if (!isRegistered()) {
                window.setTimeout({
                    delayedSlotAbout(1,slotName,callback)
                }, 100) // do not get message straight away
                return
            }
            //console.log("send " + myLastUpdated + " " + myid)
            val dict = json( "name" to slotName)
            val failFunction: () -> Unit = { -> callback(json(),null) }
            postData(slotIP + "/web/slot", JSON.stringify(dict), failFunction) { response ->
                val slot = JSON.parse<Slot2mee>(response)
                //console.log(slot)
                if(slot.slot != null) { // slot is usr of message v
                    val jsonPairs = slotPairs(slot)
                    callback(jsonPairs,slotName)
                } else {
                    callback(json(),null)
                }
            }
        }

        fun slotPairs(slot:Slot2mee) : Json? {
            var about = slot.about
            if(about == null) {
                return json()
            }
            var element = JSON.parse<Json>(about)
            //console.log(element)
            if(element == null ) {
                return json()
            }
            //console.log("Done")
            return element
        }

        fun slot(slotName:String,callback: (Slot2mee?) -> Unit) {
            if (!isRegistered()) {
                window.setTimeout({
                    delayedSlot(1,slotName,callback)
                }, 100) // do not get message straight away
                return
            }
            //console.log("send " + myLastUpdated + " " + myid)
            val dict = json( "name" to slotName)
            val failFunction: () -> Unit = { ->  }
            postData(slotIP + "/web/slot", JSON.stringify(dict), failFunction) { response ->
                val slot = JSON.parse<Slot2mee>(response)
                slot.slotName = slotName
                callback(slot)
            }
        }

        private fun delayedSmsSlot(timeout:Int,slotName:String,callback: (Slot2mee?) -> Unit) {
            if(isRegistered()) {
                smsSlot(slotName,callback)
            } else {
                window.setTimeout({
                    delayedSmsSlot(timeout*timeout,slotName,callback)
                }, 1000 * timeout * timeout) // do not get message straight away
            }
        }

        fun smsSlot(smsid:String,callback: (Slot2mee?) -> Unit) {
            if (!isRegistered()) {
                window.setTimeout({
                    delayedSmsSlot(1,smsid,callback)
                }, 100) // do not get message straight away
                return
            }
            //console.log("send " + myLastUpdated + " " + myid)
            val dict = json( "jobid" to smsid)
            val failFunction: () -> Unit = { ->  }
            postData(slotIP + "/web/sms/slot", JSON.stringify(dict), failFunction) { response ->
                val slot = JSON.parse<Slot2mee>(response)
                slot.isSMS = true // a slot does not know it is an SMS can be used for any purpose
                callback(slot)
            }
        }

    }

    constructor(pull:Boolean = true) {
        wantsScheduledMessages = pull
        if(wantsScheduledMessages) {
        console.log("Check Msgs Starting")
            //console.log("do repeatMessageCheck")
            window.setTimeout({
                repeatMessageCheck()
            }, __waitTimeBeforeCheckForMessages) // do not get message straight away
        }
        @Suppress("UNUSED_VARIABLE") var kotlinSelf = this
        js("document.addEventListener('visibilitychange', function() {kotlinSelf.visibilityChange( document.hidden ); })")
    }

    @JsName("visibilityChange")
    fun visibilityChange(hidden: Any?) {
        if(hidden != null && hidden is Boolean ) {
            documentHidden = hidden
            var myhidden = documentHidden
            if(myhidden) {
                js("document.querySelectorAll('iframe').forEach(" +
                        "function(iframe){" + //sometimes iframe does not exist!!
                        "if(!!iframe){if(!!iframe.contentWindow) {iframe.contentWindow.postMessage({ eventType: \"2MeePlayState\",state:\"visibility\",value:myhidden}, \"*\");}}" +
                        "});")
            }
        }
    }

    var numberOfCalls = 0
    fun repeatMessageCheck() {
        window.setTimeout( ::repeatMessageCheck, 1000 * findMessagesIntervalSecs) // do self again
      //  console.log("repeatMessageCheck")
        numberOfCalls = numberOfCalls + 1
        if(numberOfCalls == 2) {
            findMessagesIntervalSecs = findMessagesIntervalSecs * 2
        }
        if(numberOfCalls == 5) {
            findMessagesIntervalSecs = findMessagesIntervalSecs * 2
        }
        if(numberOfCalls == 10) {
            findMessagesIntervalSecs = findMessagesIntervalSecs * 2
        }
        // If document not active reduce findEvents
        if(documentHidden) {
            if(numberOfCalls % 4 == 0) {
                findMessages()
            }
        } else {
            findMessages()
        }
    }

    fun allowNewLaunch() {
        if(!smsOrUserEventActive) {
            console.log("allowNewLaunch")
            canLaunchEvent = true
            triggerScheduledMessageIfReady(null)
        }
    }

    fun onCloseNoIframe(modalElement: HTMLElement) {
        @Suppress("UNUSED_VARIABLE") val player2mee = this.player2mee
        js("player2mee.onClosed();")
        smsOrUserEventActive = false
        onClose(modalElement)
    }

    fun onBeforeNoIframe(modalElement: HTMLElement) {
        @Suppress("UNUSED_VARIABLE") val registered = getRegistered()
        @Suppress("UNUSED_VARIABLE") val xapi = xapiheader()
        @Suppress("UNUSED_VARIABLE") val jobId = theLaunchedJobId + ".mp4"
        player2mee = js("Player2mee(jobId,registered,xapi);")
        onBefore(modalElement)
    }

    fun onClose(modalElement: HTMLElement) {
        window.clearTimeout(notPlayingTimeout)
        //console.log("onClose")
        smsOrUserEventActive = false
        val eventID = theLaunchedEvent
        if (eventID != null) {
            doneLaunchedMessage(eventID)
            theLaunchedEvent = null
        }
        window.setTimeout(::allowNewLaunch, 1000 * 30 * 1) // wait a bit before allowing new launch.
    }



    var notPlayingTimeout : Int  = 0
    var expiryTimeMilliSecs : Double? = null
    val defaultExtraExpiry = 3*60*1000

    fun timoutCloseButNotIfPlaying() {
        var timeout = 60*60*1000
        if(expiryTimeMilliSecs != null) {
            timeout = (expiryTimeMilliSecs!! - Date().getTime()).toInt()
            if(timeout < defaultExtraExpiry) {
                timeout = defaultExtraExpiry // should be at least 3 min reaction time
            }
        }
        notPlayingTimeout = window.setTimeout(
            js("function(){if(typeof Swal !== 'undefined') {Swal.close();}}")
            , timeout)
       // console.log("set delay:" + timeout)
    }

    fun onBefore(modalElement: HTMLElement) {
        timoutCloseButNotIfPlaying()
    }

    fun checkPlayState(message:dynamic) {
        if(message == null) return
        val state = message.state
        if(state != null && state is String) {
            if(state == "play") {
                window.clearTimeout(notPlayingTimeout)
                //console.log("clearDelay")
                timoutCloseButNotIfPlaying()
            }
        }
    }

    private fun asNumber(asNum:String?) : Int? {
        if( asNum != null) {
            var isNum: Int? = null
            if (jsTypeOf(asNum) == "string") {
                isNum = asNum.toIntOrNull()
            } else {
                js("isNum = asNum")
            }
            return isNum
        }
        return null
    }

    fun ServerResponseAsJson(response:String?) : MessagesFromServer? {
        if(response == null) return null
        try {
            val json = JSON.parse<MessagesFromServer>(response)
            return json
        } catch(e:Throwable) {
            return null
        }
    }

    fun multiPageTimeForPullOK() : Boolean {
        val lookNextStr = LocalStorage.getItem("_2meeNextUpdateAt")
        if(lookNextStr != null) {
            var lookNext = lookNextStr.toDoubleOrNull()
            if(lookNext != null) {
                val current = Date().getTime()
                if(documentHidden) {
                   lookNext = lookNext + findMessagesIntervalSecs*1000 * 10 // wait ten times as long if hidden.
                }
                if (current > lookNext) {
                    return true
                } else {
                    // check time is not too large ... maybe user set time
                    val diff = lookNext - current
                    if(diff > 60*60*1000) { // 1hr too large so ignore
                        return true
                    }
                    return false
                }
            }
        }
        return true // default is true
    }

    fun findMessages() {
        if (!isRegistered()) return

        if(!multiPageTimeForPullOK()) { // if other page has pulled recently do not do it now,
            if(!documentHidden) {
                triggerScheduledMessageIfReady(null)
            }
            return
        }

        if(!Appear2mee.findEventsLock.aquire()) {
            return
        }
        val myid = find2meeWebToken()
        var myLastUpdated = LocalStorage.getItem("_2meeLastUpdatedAt")
        if (myLastUpdated == null) {
            myLastUpdated = "0"
        }

        //console.log("send " + myLastUpdated + " " + myid)
        val dict = json("_id" to myid, "lastUpdatedAt" to myLastUpdated, "topics" to useTopics.keys)
        var filter = LocalStorage.getItem("_2meeFilter")
        if(filter != null) {
            try {
                val json = JSON.parse<Json>(filter)
                dict["filter"] = json
            } catch(e:Throwable) {
            }
        }
        val failFunction: () -> Unit = { -> Appear2mee.findEventsLock.release() }
        postData(pullIP + "/web/pull", JSON.stringify(dict), failFunction) { response ->
            //console.log(response)
            var eventsAll : Map<String, MessageSchedule>? = null

            val json = ServerResponseAsJson(response)
            if(json != null) {
                val newTimeInterval = asNumber(json.timeIntervalSecForNextCall)
                if( newTimeInterval != null) {
                    findMessagesIntervalSecs = newTimeInterval
                }
                val lookNext = Date().getTime() + (findMessagesIntervalSecs-1)*1000 // -1000 (1 sec) so that self timer will always trigger. This is used for other pages.
                LocalStorage.setItem("_2meeNextUpdateAt", lookNext.toString() )
                val lastUpdatedAt = asNumber(json.lastUpdatedAt)
                if (lastUpdatedAt == null) return@postData // bad response
                if(myLastUpdated != lastUpdatedAt.toString()) {
                    LocalStorage.setItem("_2meeLastUpdatedAt", lastUpdatedAt.toString())
                }
                val events = json.events
                if (events != null) {
                    val eventsList = events.unsafeCast<Array<MessageSchedule>>().toList()
                    eventsAll = mergeMessages(eventsList)
                }
            }
            Appear2mee.findEventsLock.release()
            // see if anything is ready
            triggerScheduledMessageIfReady(eventsAll)
        }
    }

    private fun currentMessagesMap(): MutableMap<String, MessageSchedule> {
        val events = currentMessagesAsPairs()
        val map = mutableMapOf<String, MessageSchedule>()
        if(events != null) {
            val now = Date().getTime()
            events.forEach { pair ->
                val (play, expiry) = pair.second.playAndExpiryMilliSecs()
                if (play == null || expiry == null || now > expiry) {
                    // do not use.
                } else {
                    map[pair.first] = pair.second
                }
            }
        }
        return map
    }

    private fun currentMessagesAsPairs(): Array<Pair<String, MessageSchedule>>? {
        val currentEventsString = LocalStorage.getItem("_2meeEvents")
        if (currentEventsString == null) return null
        var events: Array<Pair<String, MessageSchedule>>? = null
        try {//if cannot read this then delete it!
            events = JSON.parse<Array<Pair<String, MessageSchedule>>>(currentEventsString)
        } catch (e: Throwable) {
            currentMessages(null)
        }
        if(events != null) {
            val goodevents = ArrayList<Pair<String, MessageSchedule>>()
            events.forEach { pair ->
                if (pair.first != null && jsTypeOf(pair.first) == "string" && validMessage(pair.second)) {
                    goodevents.add(pair)
                }
            }
            if(goodevents.size > 0) {
                return goodevents.toTypedArray()
            }
        }
        return events
    }

    fun doneLaunchedMessage(removeEventID: String): Boolean {
        val events = currentMessagesAsPairs()
        val good = mutableListOf<Pair<String, MessageSchedule>>()
        var done = false
        if(events != null) {
            events.forEach { pair ->
                if (pair.first != removeEventID) {
                    good.add(pair) // order maintained
                } else {
                    done = true
                }
            }
        }
        currentMessages(good)
        return done
    }

    fun retimeLaunchedMessage(removeEventID: String, _secs:Int): Boolean {
        var millisecs = _secs * 1000.0
        val events = currentMessagesAsPairs()
        val good = mutableListOf<Pair<String, MessageSchedule>>()
        var done = false
        if(events != null) {
            events.forEach { pair ->
                if (pair.first != removeEventID) {
                    good.add(pair) // order maintained
                } else {
                    //console.log("found:")
                    val info = pair.second
                    var playTime = info.playTime
                    var expiryTimeSecs = info.expiryTime
                    if( playTime != null && expiryTimeSecs != null) {
                        val now = Date().getTime()
                        var diffMilliSecs = (now - playTime)
                        //console.log("now:" + now)
                        //console.log("play:" + playTime)
                        if(diffMilliSecs < 0) {
                            diffMilliSecs = 0.0 // some error
                        }
                        //console.log("expiry:" + expiryTime)
                        //console.log("diff:" + diff)
                        val expiryTimeLeftSecs = expiryTimeSecs - (diffMilliSecs / 1000) // expiry is seconds
                        //console.log("expiryLeft:" + expiryTimeLeft)
                        if(expiryTimeLeftSecs > 0 ) {
                            console.log("Rescheduled")
                            expiryTimeSecs = expiryTimeLeftSecs
                            playTime = now + millisecs
                            //console.log("new play:" + playTime)
                            val newEvent = MessageSchedule(playTime,expiryTimeSecs,info.active,info.eventid,info.topics)
                            val newPair = Pair<String,MessageSchedule>(pair.first,newEvent)
                            good.add(newPair) // possible out of order
                            done = true
                        } else {
                            console.log("Not Rescheduled:reschedule time is after expiry")
                        }
                    }
                }
            }
        }
        if(done) {
            //console.log("reorder:")
            val result = good.toList().sortedWith(compareBy<Pair<String, MessageSchedule>> { it.second.playTime })
            currentMessages(good)
        } else {
            currentMessages(good)
        }
        return done
    }

    private fun currentMessages(events: List<Pair<String, MessageSchedule>>?) {
        val good = mutableListOf<Pair<String, MessageSchedule>>()
        if(events != null) {
            val now = Date().getTime()
            events.forEach { pair ->
                if (pair.first != null && jsTypeOf(pair.first) == "string" && validMessage(pair.second)) {
                        val (play, expiry) = pair.second.playAndExpiryMilliSecs()
                        if (play == null || expiry == null || now > expiry) {
                            // do not use has expired
                        } else {
                            good.add(pair)
                        }
                }
            }
        }
        val json = JSON.stringify(good)
        LocalStorage.setItem("_2meeEvents", json)
    }

    private fun validMessage(event:MessageSchedule?):Boolean {
        if(event == null ) return false
        if(jsTypeOf(event.eventid) != "string") return false
        if(jsTypeOf(event.playTime) != "number") return false
        if(jsTypeOf(event.expiryTime) != "number") return false
        if(jsTypeOf(event.active) != "boolean") return false
        return true
    }

    private fun mergeMessages(list: List<MessageSchedule>): Map<String, MessageSchedule> {
        val events = currentMessagesMap()
        list.forEach { event ->
            if(validMessage(event)) {
                if(event.eventid != null) {
                    events[event.eventid] = event
                    if(event.active == false) {
                        events.remove(event.eventid)
                    }
                }
            }
        }
        val result = events.toList().sortedWith(compareBy<Pair<String, MessageSchedule>> { it.second.playTime })
        currentMessages(result)
        return result.toMap()
    }

    fun isAllowedTopic(event:MessageSchedule) : Boolean {
        val topics = event.topics
        if(topics == null) {
           return !onlyTopics
        }
        if(topics.count() == 0) {
            return !onlyTopics
        }
        for(topic in topics ) {
            if(useTopics.containsKey(topic)) return true
        }
        return false
    }

    fun triggerScheduledMessageIfReady(events: Map<String, MessageSchedule>?) {
        if (!canLaunchEvent || smsOrUserEventActive) return
        if(!wantsScheduledMessages) return
        if (isOnPause) return
        if(!Appear2mee.eventLock.aquire()) {
            return
        }
        canLaunchEvent = false
        var validEvents = events
        if (validEvents == null) validEvents = currentMessagesMap()
        var attemptedLaunch = false
        for (eventPair in validEvents) {
            //console.log("pair",eventPair)
            val event = eventPair.value
            val (play, expiry) = event.playAndExpiryMilliSecs()
            if (play == null) continue
            if (expiry == null) continue
            val now = Date().getTime()
            if (now > play && now < expiry) {
                if(event.active == false) continue
                if(!isAllowedTopic(event)) continue
                val eventid = event.eventid
                if (eventid == null) break
                val myid = find2meeWebToken()
                val dict = json("_id" to myid, "eventid" to eventid)
                attemptedLaunch = true
                val failFunction: () -> Unit = { -> canLaunchEvent = true
                    Appear2mee.eventLock.release()
                }
                postData(pullIP + "/web/event", JSON.stringify(dict), failFunction) { response ->

                    var message2mee : Message2mee? = null
                    try {
                        message2mee = JSON.parse<Message2mee?>(response)
                        //console.log("EVENT",message2mee)
                        if(message2mee != null) {
                            val available = message2mee.available
                            if(available != null && available) {
                                var keyPairs = message2mee.keyValues
                                if (keyPairs != null) {
                                    var data = JSON.parse<Json>(keyPairs)
                                    var link = data["__openExternalURL"]
                                    if (link != null) {
                                        //console.log("message lnk", link)
                                        message2mee.link = link as? String
                                    }
                                }
                            } else {
                                message2mee = null // message was not available
                            }
                        }
                    } catch (e:Throwable) {}
                    if (message2mee != null) {
                        var useExpiry = expiry
                        val time = (expiry - now)
                        if(time < defaultExtraExpiry) {
                            useExpiry = useExpiry + (defaultExtraExpiry - time) // should be at least 3 min react
                        }
                        message2mee.expiryTimeMilliSecs = useExpiry
                        val reportEventWithJobID = message2mee.jobId
                                ?: eventid // if no jobId just use event. Some error!
                        theLaunchedEvent = eventid
                        tryLaunchEventJSWithTopic(message2mee, event.topics, reportEventWithJobID)
                    } else {
                        canLaunchEvent = true // nothing actually launched
                    }
                    Appear2mee.eventLock.release()
                }
                break
            }
        }
        if (!attemptedLaunch) {
            Appear2mee.eventLock.release()
            canLaunchEvent = true
        }
    }

    /*
    fun launchEventInternalMessage(message: InternalMessage2mee, jobId: String) {
        canLaunchEvent = false
        cannedEventActive = true
        message.expiryTimeMilliSecs = Date().getTime() + (24 * 60 * 60 * 1000)
        _launchEventInternalMessage(message,jobId)

    }

    fun _launchEventInternalMessage(message: InternalMessage2mee, jobId: String) {
        // NOTE. EVEN IF ERRORS ARE ENCOUNTERED WE STILL TRY THE DIALOG
        // This is just an attempt to preload
        // afterImageLoadedlaunchEventJS(message, jobId)
        // return

        var imageSrc = CDN + jobId
        val mUrl = message.options.url as String
        if(mUrl != null ){
            imageSrc = mUrl.substringBeforeLast(".")
        }
        if(LocalStorage._getItem("_2meeWebp") != null) {
            imageSrc = imageSrc + ".webp"
        } else {
            imageSrc = imageSrc + ".png"
        }
        val image = Image()
        var counter = 0

        // async load means we are not sure which will return first...
        image.onload = fun(s: Event): Unit {
                afterImageLoadedlaunchEventInternalMessage(message, jobId)
        }
        image.onerror = fun(a: dynamic, b:String, c:Int, d:Int, e:Any?): Unit {
                afterImageLoadedlaunchEventInternalMessage(message, jobId);
        }
        image.src = imageSrc
    }


    fun afterImageLoadedlaunchEventInternalMessage(message: InternalMessage2mee, jobId: String) {
        val player2mee = Player2meeClass(message.options)
        var html =player2mee.html()
        if(message.link == null ) {
            html = noLink + html
        } else {
            html = linkExtra + html
        }
        val buttons = message.buttons
        var ok : String? = null
        if (buttons != null && buttons.size > 0) {
            for (button in buttons) {
                if (button.type == "ok") {
                    if (button.title != null) {
                        ok = button.title
                    }
                }
            }
        }

        val options = SweetAlertOptions()
        options.position = position
        options.backdrop = backdropValue
        options.allowOutsideClick = outsideClickEnabled
        options.background = "transparent"

        options.html = html
        options.heightAuto = sweetAlertHeightAuto

        /* using custom classes so do not show */
        options.showCloseButton = false
        options.showConfirmButton = false
        /*
        if(ok == null || ok == "") {
            options.showConfirmButton = false
        } else {
            options.showConfirmButton = true
            options.confirmButtonText = ok
        }
        */

        options.willOpen = ::onBefore
        options.willClose = ::onClose
        options.didDestroy =  {
            player2mee.close();
        }
        options.didOpen =  {
            var x = document.getElementsByClassName("swal2-container")
            for (i in x.asList()) {
                var style = i.getAttribute("style");
                if(style == null) style = "";
                i.setAttribute("style",style + "z-index:" + __swal2Zindex.toString() + ";")
            }
            player2mee.open();
        }


        //options.timer = message.expireInMilliSec

        js("options.customClass = {\n" +
                "                    container: 'appear2mee-container',\n" +
                "                    popup: 'appear2mee-popup',\n" +
                "                    closeButton: 'appear2mee-close'\n" +
                "                }")

        // used in js
        @Suppress("UNUSED_VARIABLE") val kotlinSelf = this
        @Suppress("UNUSED_VARIABLE") val reportWithJobId = jobId
        @Suppress("UNUSED_VARIABLE") val messageData = message
        @Suppress("UNUSED_VARIABLE") var sweet = sweetPath
        @Suppress("UNUSED_VARIABLE") var sweetcss = sweetcssPath

        @Suppress("UNUSED_VARIABLE") var firstToFire = true;
        expiryTimeMilliSecs = message.expiryTimeMilliSecs
        if(!documentHidden) {
            js("yep2meenope({test: (typeof Swal === 'undefined' || typeof Swal.fire === 'undefined') , yep: [sweet,sweetcss]," +
                    " complete: function(){" +
                    "if (typeof VideoContext !== 'function') return;" +  // first time thru --- one of these will run the Swal.fire, which ever returns last
                    "if(firstToFire){firstToFire=false;" + // once both have loaded call only once
                    "Swal.fire(options).then(function(result) { kotlinSelf.sweetAlertResult(result,reportWithJobId,messageData);});" +
                    "}" +
                    "}});")
            @Suppress("UNUSED_VARIABLE") var videoContext = videoContextPath
            js("yep2meenope({test: (typeof VideoContext !== 'function') , yep: [videoContext]," +
                    " complete: function(){" +
                    "if(typeof Swal === 'undefined' || typeof Swal.fire === 'undefined') return;" + // one of these will run the Swal.fire, which ever returns last
                    "if(firstToFire){firstToFire=false;" + // once both have loaded call only once
                    "Swal.fire(options).then(function(result) { kotlinSelf.sweetAlertResultEventMessage(result,reportWithJobId,messageData,player2mee);});" +
                    "}" +
                    "}});")
        }
    }

    @JsName("sweetAlertResultEventMessage")
    fun sweetAlertResultEventMessage(result: SweetAlertResult, jobId: String, message: InternalMessage2mee, player:Player2meeClass) {
        player.pause()
        report(jobId, result,(message.slot != null),null)
        if (result.value != null) {
            val slot = message.slot
            if (message.link != null) {
                if(slot != null) {
                    reportSlotMessage(slot,"link",false)
                }
                window.setTimeout({
                    var win = window.open(message.link, "_blank");
                    if (win != null) {
                        win.focus()
                    }
                },100);
                //window.location.href = message.link
            } else {
                if(slot !=null) {
                    var myfunction = messageActionFn
                    if (myfunction != null) {
                        reportSlotMessage(slot, "userFn",slot.isSMS ?: false)
                        window.setTimeout({
                            myfunction(slot.slotName ?: "unknown", slot.isSMS ?: false)
                        }, 100)
                    }
                }
            }
            //console.log("result " + result.value + " " + event)
        } else {
            //console.log("result " + result.dismiss + " " + event)
        }
    }
*/

    fun tryLaunchEventJSWithTopic(message: Message2mee, topics : Array<String>? ,jobId: String) {
        var sendtopics = topics
        if(sendtopics == null) sendtopics = arrayOf()
        var jsonTopics = js("Object.assign({}, sendtopics);")
        //console.log(jsonTopics)
        var keyPairs = message.keyValues
        if(keyPairs == null) keyPairs = "{}"
        var sendKeyPairs = JSON.parse<Json>(keyPairs)
        //console.log(sendKeyPairs)
        val _shouldDisplayMessage = shouldDisplayMessage
        var ret = 0 // the default as accept
        if(_shouldDisplayMessage != null) {
            ret =  _shouldDisplayMessage(sendKeyPairs,jsonTopics,message.jobId ?: "unknown")
        }
        if(ret == 0) {
            launchEventJS(message,MessageType.PULLED)
        } else {
            if( ret < 0) {
                console.log("ShouldDisplayMessage:Do not show message:" + message.jobId)
                val event = theLaunchedEvent
                if (event != null) {
                    doneLaunchedMessage(event)
                }
                allowNewLaunch()
            } else {
                if (ret > 0) {
                    console.log("ShouldDisplayMessage:Do show message later:" + message.jobId)
                    val event = theLaunchedEvent
                    if (event != null) {
                        retimeLaunchedMessage(event,ret)
                    }
                    allowNewLaunch()
                }
            }
        }
    }

    fun isCurrentDesktopMessage(element:String) :Boolean {
        val swalContainer : HTMLElement? = js("Swal.getContainer()")
        if(swalContainer != null) {
            return swalContainer.classList.contains("appear2mee-container-desktop")
        }
        return false
    }

    fun swapLoaderForBanner(element:String) {
        //console.log("swapLoaderForBanner")
        val _loader : dynamic = document.getElementById( "appear-loader-holder")
        val loader : HTMLElement? = _loader // AMP systems override HTMLElement cannot use as?
        val _banner : dynamic = document.getElementById( "appear-banner-holder")
        val banner : HTMLElement? = _banner // AMP systems override HTMLElement cannot use as?
        if(banner != null && loader != null) {
            loader.style.display = "none"
            banner.style.display = "block"
        }
    }

    var swalIframeElementId : String? = null
    fun checkIfSwalLaunchRequired(element:String) {
        if(swalIframeElementId == element) {
            val allclass = document.getElementsByClassName("appear-ident-" + element)
            val myelemid = swalIframeElementId
            swalIframeElementId = null
            if(allclass.length == 0 || allclass.length > 1 ) {
                console.warn("checkIfSwalLaunchRequired: no iframe or too many for:", myelemid)
            }
            val iframe = allclass[0] as? HTMLIFrameElement

            if(iframe != null) {
                //console.log("checkIfSwalLaunchRequired",myelemid )
                val contentWindow = iframe.contentWindow
                js("contentWindow.postMessage({ eventType: \"2MeePlayState\",state:\"playPermission\",element:myelemid }, \"*\");")
                if(isCurrentDesktopMessage(element)) {
                    //console.log("current desk",myelemid )
                    window.setTimeout({
                        swapLoaderForBanner(element)
                    }, 5000)
                    val swalContainer : HTMLElement = js("Swal.getContainer()")
                    swalContainer.style.display = "flex" // overloads appear2mee-container-desktop
                } else {
                    //console.log("remove wait",myelemid )
                    removeWaitClassesFromWebpage()
                    val swalContainer : HTMLElement = js("Swal.getContainer()")
                    swalContainer.style.display = "flex" // overloads appear2mee-container

                }
            } else {
                console.warn("checkIfSwalLaunchRequired: no iframe for:", myelemid)
            }

        }
    }

    fun addWaitClassesToWebpage() {
        var body = document.getElementsByTagName("body")[0]
        if(body != null) {
            body.addClass("appear2mee-wait")
        }
        var html = document.getElementsByTagName("html")[0]
        if(html != null) {
            html.addClass("appear2mee-wait")
        }
    }
    fun removeWaitClassesFromWebpage() {
        var body = document.getElementsByTagName("body")[0]
        if(body != null) {
            body.removeClass("appear2mee-wait")
        }
        var html = document.getElementsByTagName("html")[0]
        if(html != null) {
            html.removeClass("appear2mee-wait")
        }
    }



    fun fileExists(url: String, callback: (Boolean) -> Unit) {
        val xmlHttp = XMLHttpRequest()
        xmlHttp.open("HEAD", url, true)
        xmlHttp.onload = {
            if (xmlHttp.readyState == 4.toShort()) {
                if (xmlHttp.status == 200.toShort()) {
                    // console.log(xmlHttp.responseText)
                    callback(true)
                } else {
                    callback(false)
                }
            }
        }
        xmlHttp.onerror = {
            callback(false)
        }
        xmlHttp.onabort = {
            callback(false)
        }
        xmlHttp.ontimeout = {
            callback(false)
        }
        xmlHttp.send()
    }

    fun allowWebp() : Boolean {
        val allowWebP = LocalStorage._getItem("_2meeWebp")
        if(allowWebP != null) {
            return (allowWebP == "webp")
        }
        return false
    }

    fun positionFromCode(pos: String) : String {
        var cssPosition = position // fallback
        when (pos) {
            "br" -> {
                cssPosition="bottom-right"
            }
            "bl" -> {
                cssPosition="bottom-left"
            }
            "tl" -> {
                cssPosition="top-left"
            }
            "tr" -> {
                cssPosition="top-right"
            }
            "t" -> {
                cssPosition="top"
            }
            "b" -> {
                cssPosition="bottom"
            }
            "l" -> {
                cssPosition="left"
            }
            "r" -> {
                cssPosition="right"
            }
            "c" -> {
                cssPosition="center"
            }
            else -> {

            }
        }
        return cssPosition
    }

    fun useDesktopBanner(slot: Slot2mee,messageType: MessageType, callback: (String?, String?) -> Unit)  {
        val isSMS = slot.isSMS
        if(isSMS != null && isSMS) {
            var ext = ".png"
            if(allowWebp()){ ext = ".webp"}
            var sms = paramFromBrowser("2meeSMS")
            if(sms == null ) { paramFromBrowser("2meeLnk") }
            if(sms == null ) {
                callback(null,null)
                return
            }
            val banner = CDNLoaderFiles + sms + "deskbanner-play" + ext
            val loader = CDNLoaderFiles + sms + "deskloader" + ext
            val mycallback: (Boolean) -> Unit = { success: Boolean ->
                if(success) {
                    callback(loader,banner)
                } else {
                    callback(null,null)
                }
            }
            imageExists(loader,mycallback)
        }  else {
            callback(null,null)
            return
        }
    }

    fun launchEventJS(message: Message2mee, messageType:MessageType) {
        canLaunchEvent = false // just to be sure, should be done before this
        var ident = Appear2mee.uuidv4()
        if(message?.slot != null) {
            message?.slot?.element = ident
            var slot = message?.slot
            if(slot != null) {
                ctaInfo(slot, ident)
            }
        }
        val html = messageHTML(message, ident, messageType)
        if (html == null) {
            canLaunchEvent = true
            return
        }
        _launchEventJS(html,ident, message, messageType)
    }


    fun _launchEventJS(_html:String, ident : String, message: Message2mee, messageType: MessageType) {
        var html = _html
        if(message.link == null ) {
            if(dismissUseLight) {
                html = noLink + html
            } else {
                html = noDarkLink + html
            }
        } else {
            if(dismissUseLight) {
                html = linkExtra + html
            } else {
                html = darkLinkExtra + html
            }
        }

        html = replaceIfLocalOrIBMNetwork(html)

        swalIframeElementId = ident

        val options = SweetAlertOptions()

        if(messageType == MessageType.SMS || messageType == MessageType.LNK) {
            val css = paramFromBrowser("2meeCSS")
            var cssPosition="bottom-right" // the default
            if(css != null) {
                when (css) {
                    "appearBottomRight" -> {
                        cssPosition="bottom-right"
                    }
                    "appearBottomLeft" -> {
                        cssPosition="bottom-left"
                    }
                    "appearTopLeft" -> {
                        cssPosition="top-left"
                    }
                    "appearTopRight" -> {
                        cssPosition="top-right"
                    }
                    else -> {
                       cssPosition = css // assume user knows what they are doing!!!
                    }
                }
            }
            options.position = cssPosition
        } else {
            var msgPosCode = message.position
            if(msgPosCode != null) {
                    options.position = positionFromCode(msgPosCode)
            } else {
                options.position = position
            }
        }

        var useToast = !message.blocking
        useToast =  (messageType == MessageType.PAGE || useToast)

        if(useToast) {
            js("delete options.allowEnterKey;")
            js("delete options.backdrop;")
            js("delete options.focusConfirm;")
            js("delete options.heightAuto;")
            js("delete options.allowOutsideClick;")
            //console.log("toast")
        } else {
            options.backdrop = backdropValue
            options.allowOutsideClick = outsideClickEnabled
            options.heightAuto = sweetAlertHeightAuto
        }
        options.toast = useToast

        options.background = "transparent"
        options.html = html

        //options.showCancelButton = true
        //options.cancelButtonText = "Cancel"

        /* using custom classes so do not show */
        options.showCloseButton = false
        options.showConfirmButton = false

        options.willOpen = ::onBefore
        options.willClose = ::onClose
        //options.didRender = ::didRender
        options.didOpen =  {
            var x = document.getElementsByClassName("swal2-container")
            for (i in x.asList()) {
                var style = i.getAttribute("style")
                if (style == null) style = ""
                i.setAttribute("style", style + "z-index:" + __swal2Zindex.toString() + ";")
            }
        }

        //options.timer = message.expireInMilliSec

        console.log("offset:",message.offset)
        if(message.isDesktop) {
            var containClass = "appear2mee-container-desktop"
            if(useToast) {
                containClass = "appear2mee-container-desktop appear2mee-toast"
            }
            if(message.offset > 0) {
                //containClass += " appear2mee-large-logo"
            } else {
                containClass += " appear2mee-nolarge-logo"
            }
            js("options.customClass = {" +
                    "container: containClass," +
                    "popup: 'appear2mee-popup'," +
                    "closeButton: 'appear2mee-close'}")

        } else {
            var containClass = "appear2mee-container"
            if(useToast) {
                containClass = "appear2mee-container appear2mee-toast"
            }
            if(message.offset > 0) {
               // containClass += " appear2mee-large-logo"
            } else {
                containClass += " appear2mee-nolarge-logo"
            }

            js(
                "options.customClass = {\n" +
                        "                    container: containClass,\n" +
                        "                    popup: 'appear2mee-popup',\n" +
                        "                    closeButton: 'appear2mee-close'\n" +
                        "                }"
            )
            addWaitClassesToWebpage()
        }

        // used in js
        @Suppress("UNUSED_VARIABLE") val kotlinSelf = this
        @Suppress("UNUSED_VARIABLE") val reportWithJobId = message.jobId
        @Suppress("UNUSED_VARIABLE") val messageData = message
        @Suppress("UNUSED_VARIABLE") var sweet = sweetPath
        @Suppress("UNUSED_VARIABLE") var sweetcss = sweetcssPath
        expiryTimeMilliSecs = message.expiryTimeMilliSecs

        if(!documentHidden) {
            initIframeListener()
            js("yep2meenope({test: (typeof Swal === 'undefined' || typeof Swal.fire === 'undefined') , yep: [sweet,sweetcss]," +
                    " complete: function(){" +
                    "Swal.fire(options).then(function(result) { kotlinSelf.sweetAlertResult(result,reportWithJobId,messageData);});" +
                    "}});")
            // js("Swal.fire(options).then(function(result) { kotlinSelf.sweetAlertResult(result,reportWithJobId,messageData);});")
        }
    }

    var linkExtra = "<div class='appear2mee-outer-wrapper'><div class='appear2mee-action-background double'>" +
            "</div><div onclick='Swal.close();' class='appear2mee-action-close-only'>" +
            "</div>" +
            "<div class='appear2mee-action-link-only' onclick='Swal.clickConfirm();'>" +
            "</div></div>"

    var noLink = "<div class='appear2mee-outer-wrapper'>" +
            "<div class='appear2mee-action-background single'>" +
            "</div><div onclick='Swal.close();' class='appear2mee-action-close-only'>" +
            "</div></div>"

    var darkLinkExtra = "<div class='appear2mee-outer-wrapper'><div class='appear2mee-action-background double'>" +
            "</div><div onclick='Swal.close();' class='appear2mee-action-close-only-dark'>" +
            "</div>" +
            "<div class='appear2mee-action-link-only' onclick='Swal.clickConfirm();'>" +
            "</div></div>"

    var noDarkLink = "<div class='appear2mee-outer-wrapper'>" +
            "<div class='appear2mee-action-background single'>" +
            "</div><div onclick='Swal.close();' class='appear2mee-action-close-only-dark'>" +
            "</div></div>"

    fun messageHTML(message: Message2mee, ident:String, messageType: MessageType):String? {
        var html = message.html
        if (html != null) {
            if(CDN == "./") {
                html = html.replace(
                    "src=\"https://exchange.cdnedge.bluemix.net/web/current2MeePlayer.html",
                    "src=\"./current2MeePlayer.html"
                )
                html = html.replace(
                    "src=\"https://cdn.2mee.com/web/current2MeePlayer.html",
                    "src=\"./current2MeePlayer.html"
                )
            } else {
                html.replace(
                    "src=\"https://exchange.cdnedge.bluemix.net/web/current2MeePlayer.html",
                    "src=\"https://cdn.2mee.com/web/current2MeePlayer.html"
                )
            }
        }

        val registered = getRegistered()
        val xapi = xapiheader()
        val webToken = find2meeWebToken()
        if (registered != null) {
            if (html != null) {
                var replaceWith : String
                if(message.canPlay) {
                    replaceWith = "?rpt=true&canPlay=true&reg=" + registered + "&client=" + xapi + "&url="
                } else {
                    replaceWith = "?rpt=true&reg=" + registered + "&client=" + xapi + "&url="
                }
                html = html.replace("?url=", replaceWith)


                // give ident
                html = html.replace("<iframe", "<iframe class='" + "appear-ident-" + ident + "' data-appear2mee='" + messageToJsonString(message) + "'")
                replaceWith = "&ident=" + ident + "&url="
                html = html.replace("&url=", replaceWith)

                html = html.replace("height=\"307px\"", "height=\"314px\"")

                val bannerNumber = bannerPlayer(message.keyValues)
                var myPlayer = player // default
                if(bannerNumber != 0) {
                    if(bannerNumber == 3) myPlayer = backgroundPlayer
                    else myPlayer = plinthPlayer
                }
                // button labels
                var keyPairs = message.keyValues
                if(keyPairs != null) {
                    if(keyPairs == null) keyPairs = "{}"
                    var data = JSON.parse<Json>(keyPairs)
                    //console.log("pairs",data)
                    var label = data["2meePlayButton"]
                    //console.log("pairs",label)
                    if(label != null) {
                        //console.log("pairs",label)
                        replaceWith = "&lblT=" + encodeURIComponent(label as String) + "&url="
                        html = html.replace("&url=", replaceWith)
                    }
                    label = data["2meeOfferButton"]
                    //console.log("pairs",label)
                    if(label != null) {
                        //console.log("pairs",label)
                        replaceWith = "&lblO=" + encodeURIComponent(label as String) + "&url="
                        html = html.replace("&url=", replaceWith)
                    }

                    addPositionBlocking(message,data)
                    var  bannerWidth : Int?  = null
                    if(bannerNumber == 0) {
                        html = addBannerData(html,data, message.link != null,messageType,null,message)
                    } else {
                        if (bannerNumber == 3) {
                            html = addBackgroundData(html,data, message.link != null, messageType, null,message)
                            // background does similar to banner width
                        } else {
                            //  console.log("data:",data)
                            html = addPlinthBannerData(html, data, message.link != null, messageType, null,message)
                            bannerWidth = bannerOffsetWidth(data) + 1 // frame is one too small
                        }
                    }

                    if(bannerNumber == 3) {
                        val size = backgroundSize(data,bannerNumber)
                        if(size != null) {
                            html = html.replace("width=\"270px\"", "width=\"" + size.first + "\"")
                            val height = "height=\"" + size.second + "\""
                            html = html.replace("height=\"320px\"", height) // different possiblity in iframe string
                            html = html.replace("height=\"314px\"", height)
                        } else {
                            console.error("backgroundSize not found")
                        }
                    } else {
                        if(bannerWidth != null) {
                            html = html.replace("width=\"270px\"", "width=\"" + bannerWidth + "px\"")
                        } else {
                            if (html.contains("&ban=1&")) { // is banner
                                message.isDesktop = true
                                html = html.replace("width=\"270px\"", "width=\"550px\"")
                            }
                        }
                        val offset = bannerOffset(data, bannerNumber)
                        val height = "height=\"" + (314 + offset).toString() + "px\""
                        html = html.replace("height=\"320px\"", height) // different possiblity in iframe string
                        html = html.replace("height=\"314px\"", height)
                        //console.log("pairs",html)
                    }
                }

                if(appear2meePlayerMuteButton) {
                    replaceWith = "&mutbut=1&url="
                    html = html.replace("&url=", replaceWith)
                }

                if(appear2meePlayerSpinner) {
                    replaceWith = "&spin=1&url="
                    html = html.replace("&url=", replaceWith)
                }

                if(appear2meeBeGambleAware) {
                    replaceWith = "&gamble=1&url="
                    val lnk = appear2meeBeGambleAwareLink
                    if(lnk != null) {
                        replaceWith = "&glnk=" + encodeURIComponent(lnk) + replaceWith
                    }
                    html = html.replace("&url=", replaceWith)
                }
                if(buttonForPlayerUrl != null) {
                    replaceWith = "&button=" + buttonForPlayerUrl + "&url="
                    html = html.replace("&url=", replaceWith)
                }

                val theslot = message.slot
                console.log("message link:",message.link,theslot)
                if(message.link != null) {
                    //var link = encodeURIComponent(message.link)
                    replaceWith = "&lnk=" + "1" + "&url="
                    html = html.replace("&url=", replaceWith)
                } else {
                    if(theslot != null && ctaComplex(theslot)) {
                        console.log("complex link:",message.link,theslot)
                        replaceWith = "&cta=1" + "&url="
                        html = html.replace("&url=", replaceWith)
                        if(ctaClick(theslot)) {
                            replaceWith = "&ctafun=1&swal=1" + "&url="
                            html = html.replace("&url=", replaceWith)
                        }
                    }
                }

                if(theslot !=null && theslot.preloader != "false") {
                    replaceWith = "&pre=1" + "&url="
                    html = html.replace("&url=", replaceWith)
                }

                var user : String? = null
                if(messageType == MessageType.SMS) {
                    user = paramFromBrowser("2meeId")
                    //var link = encodeURIComponent(message.link)
                    val smsid = paramFromBrowser("2meeSMS")
                    if(smsid != null) {
                        replaceWith = "&sms=" + smsid + "&url="
                        html = html.replace("&url=", replaceWith)
                    }
                }

                if(messageType == MessageType.LNK) {
                    user = paramFromBrowser("2meeId")
                    //var link = encodeURIComponent(message.link)
                    val smsid = paramFromBrowser("2meeLnk")
                    if(smsid != null) {
                        replaceWith = "&sms=" + smsid + "&url="
                        html = html.replace("&url=", replaceWith)
                    }
                }

                if( user == null) {
                    user = getReportUserID()
                }
                if (user != null) {
                    replaceWith = "&userId=" + user + "&url="
                    html = html.replace("&url=", replaceWith)
                }
                val slot = message.slot
                if( slot != null) {
                    replaceWith = "&cap=1" + "&url="
                    html = html.replace("&url=", replaceWith)

                    val offer = slot.offer
                    if(offer!=null) {
                        html = html.replace("<iframe", "<iframe data-appear2mee-offer='" + offerToJsonString(offer) + "'")
                    }
                }

                if(myPlayer != "current2MeePlayer.html") {
                    html = html.replace("current2MeePlayer.html", myPlayer)
                }
                if(myPlayer != player) { // eventMessage src
                    html = html.replace(player, myPlayer)
                }
            }
        }
        return html
    }

    fun offerAcceptance(slot:Slot2mee?) {
        if(slot == null) return
        val offerId = slot.offer?.offerId
        val localStorageKey  = slot.offer?.dataKey
        offerAcceptance(localStorageKey,offerId)
        var elem = slot?.element
        if(elem == null) {
            elem  = "event"
        }
        ctaAccept(elem)
    }

    fun offerDismiss(slot:Slot2mee?) {
        if(slot == null) return
        val offerId = slot.offer?.offerId
        val localStorageKey  = slot.offer?.dataKey
        offerDismiss(localStorageKey,offerId)
        var elem = slot?.element
        if(elem == null) {
            elem  = "event"
        }
        ctaDismiss(elem)
    }

    @JsName("sweetAlertResult")
    fun sweetAlertResult(result: SweetAlertResult, jobId: String, message: Message2mee) {
        report(jobId, result,(message.slot != null),null)
        if (result.value != null) {
            val slot = message.slot
            val link = message.link
            offerAcceptance(slot)
            if (link != null) {
                if(slot != null) {
                    reportSlotMessage(slot,"link",slot.isSMS ?: false)
                }
                window.setTimeout({
                    var win = window.open(link, "_blank")
                    if (win != null) {
                        win.focus()
                    } else {
                        console.log("try self link")
                        win = window.open(link, "_self")
                        if (win == null) {
                            window.location.href = link
                        }
                    }
                }, windowOpenTimeoutTime)
                //window.location.href = message.link
            } else {
                console.log("function click")
                if(slot !=null) {
                    var myfunction = ctaClickName(slot)
                    if (myfunction != null) {
                        reportSlotMessage(slot, "userFn",slot.isSMS ?: false)
                        window.setTimeout({
                            window[myfunction]()
                        }, 100)
                    }
                }
            }
            //console.log("result " + result.value + " " + event)
        } else {
            val slot = message.slot
            offerDismiss(slot)
        }
    }



    fun report(jobId: String, result: SweetAlertResult, isSlot:Boolean, callback: (() -> Unit)?) {
        val registered = getRegistered()
        if (registered == null) return
        val now = Date().getTime()
        var resString = "accept"
        var dismissReason: String? = null
        if (result.value == null) {
            resString = "dismiss"
            if(result.dismiss != null) {
                dismissReason = result.dismiss.toString()
            } else {
                dismissReason = "button"
            }
        }
        val dict = json("from" to registered, "sentAt" to now, "jobid" to jobId, "result" to resString)
        if (dismissReason != null) {
            dict["reason"] = dismissReason
        }
        val userID = getUserID()
        if (userID != null) {
            dict["userID"] = userID
        }
        val webToken = find2meeWebToken()
        dict["webToken"] = webToken
        if(isSlot) {
            dict["isCap"] = isSlot
        }

        val referer = LocalStorage.getItem("_2meeReferer")
        if(referer !=null) {
            dict["externalRef"] = referer
        }

        val data = JSON.stringify(dict)
        reportToExchange(data,callback)
    }
}

fun postData(url: String, data: String, failCallback: () -> Unit, callback: (String) -> Unit) {
    val xmlHttp = XMLHttpRequest()
    xmlHttp.open("POST", url, true)
    xmlHttp.setRequestHeader("x-api-header", xapiheader())
    xmlHttp.setRequestHeader("Content-Type", "application/json")
    xmlHttp.onload = {
        if (xmlHttp.readyState == 4.toShort()) {
            if (xmlHttp.status == 200.toShort()) {
                // console.log(xmlHttp.responseText)
                callback.invoke(xmlHttp.responseText)
            } else {
                if (xmlHttp.status == 511.toShort()) {
                    throw Exception("******************  CLIENT KEY  *******************")
                } else {
                    failCallback()
                }
            }
        }
    }
    xmlHttp.onerror = {
        failCallback()
    }
    xmlHttp.onabort = {
        failCallback()
    }
    xmlHttp.ontimeout = {
        failCallback()
    }
    xmlHttp.send(data)
}

fun reportToExchange(data: String, callback: (() -> Unit)?, retry: Int = 0) {
    val failFunction: () -> Unit = { ->
        if (retry < 3) {
            val tryAgain = retry + 1
            window.setTimeout((::reportToExchange)(data, callback, tryAgain), 1000 * (tryAgain))
        }
    }
    postData(exchangeIP + "/web/report", data, failFunction) { response ->
       // console.log("reported:",data)
        if(callback != null) {
            callback()
        }
    }
}


